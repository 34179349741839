import React from "react";
import StoreManager from "../appdata";
import { callAPISelfie } from "../api";
import languageSDK from "../languages";
import { ZoomApp } from "../zoom-app/zoomapp";
import Helpers, { screenIDState } from "../helpers";
import TrueID from "../index"

var isStartFacetec = false 
export default class SelfieScreen extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: screenIDState.START, // 0 trạng thái ban đầu, 1 : calling api , 2: call api failed - hiển thị lỗi 
            errorMessage: "",
            selfieImage: ""

        }

    }

    componentDidMount() {
        if(!isStartFacetec){
            isStartFacetec = true
            console.log("facetecProdKey", StoreManager.appdata.facetecProdKey)
            ZoomApp.reloadSDK(StoreManager.appdata.facetecProdKey);
            
        } 
        setTimeout(()=>{
            // ZoomApp.reloadSDK(StoreManager.appdata.facetecProdKey);

            ZoomApp.onLivenessCheckPressed()
        }, 3000)
        ZoomApp.onComplete = this.livenessCompleteSucess

        StoreManager.appdata.thisSelfie = this
    }
    livenessCompleteSucess = (sessionResult, idScanResult, latestNetworkResponseStatus) =>{
        if (latestNetworkResponseStatus == 200) {
            console.log("sessionResult", sessionResult, sessionResult.auditTrail[0])
            this.setState({
                status: screenIDState.CALLING_API,
                selfieImage: sessionResult.auditTrail[0]
            }, ()=>{
                Helpers.loading.drawLoadingSpinner()
                callAPISelfie(sessionResult.auditTrail[0])
            });
                
        }
           
      }

    capture = () => {
       
    };

    closeScreen = () => {
        StoreManager.appdata.callback({code: 0,errorMessage: "User Close SDK"})
        TrueID.close()
    };
    actionButon = () =>{
        const { status } = this.state;
        if (status == screenIDState.CALLING_API) {
            return;
        }
        if (status == screenIDState.CALL_API_FAILED) {
            this.setState({errorMessage: '', status: screenIDState.START}, () => {
                ZoomApp.onLivenessCheckPressed()
            })
        }
    }


    render() {
        const { status, selfieImage,errorMessage } = this.state;
        let configEkyc = StoreManager.appdata.configEkyc

        var subTitle = ``
        var buttonTitle = `${languageSDK.language.trueID_retake_photo}`
        if (status == screenIDState.CALL_API_FAILED) {
            subTitle = errorMessage
        }
    
        return (
            <div style={{ textAlign: 'center', fontFamily: 'Arial, sans-serif', position: 'relative', padding: '10px', minHeight: 400 }}>
                <p style={{ color: configEkyc.titleColor, fontSize: 20,  marginBottom: '20px', fontWeight: 600 }}>{`${languageSDK.language.trueID_selfie_photo}`}</p>
                <div style={{ display: status === screenIDState.START ? 'none' : '', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                    <canvas id="loadingCanvas" width="50" height="50" style={{ display: 'none' }}></canvas>
                </div>
                <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '250px', height: '250px', borderRadius: '50%', border: '3px dashed #ccc', overflow: 'hidden', backgroundColor: '#fff' }}>
                    {selfieImage && <img src={`data:image/jpeg;base64,${selfieImage}`} alt="Selfie" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />}
                </div>

                <p style={{ position: 'absolute', top: '350px', left: '50%', transform: 'translateX(-50%)', fontSize: '16px',color: configEkyc.titleColor, fontSize: 18}}>{`${subTitle}`}</p>
                <button onClick={this.actionButon} style={{  display:  status == screenIDState.CALL_API_FAILED ? '':'none' , position: 'absolute', top: '400px', left: '50%', transform: 'translateX(-50%)', fontSize: '18px', padding: '10px 20px', borderRadius: '5px', backgroundColor: configEkyc.buttonCaptureColor, color: configEkyc.titleButtonCaptureColor, border: 'none', cursor: 'pointer' }}>
                    {`${buttonTitle}`}
                </button>
            </div>
        );
    }
    
    
}
