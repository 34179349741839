let zoomServerBaseURL = ""
let zoomAuthURL = ""
let zoomLicenseKey = ""
let zoomPublicKey = "";
// var isZoom = false

export default class StoreManager {
    static userdata = {
        token: '',
        jwtDecode: {}
    }

    static appdata = {
        defaultZoom: 1,
        media: null,
        thisApp: false,
        thisFront: false,
        thisBack: false,
        thisSelfie: false,
        accessToken: "",
        facetecProdKey: "",
        requestId: "",
        rawImage: {
            front: "",
            back: "",
            selfie: ""
        },
        callback: () => { },

        configEkyc: {
            domain: '/api',
            configEndpoint: {
                front: "/ekyc/v1.2/id/verify/front",
                back: "/ekyc/v1.2/id/verify/back",
                selfie: "/ekyc/v1.2/selfie/verify",
                complete: "/ekyc/v1.2/complete",
                nfcqrverify: "/ekyc/v2.0/nfcqr/verify",
                nfcrar: "/ekyc/v1.2/nfcqr/upload",
                ocr: "/ekyc/v1.2/id/ocr",
                nfcbshield: "/ekyc/v1.2/nfcqr/upload",
                createrequest: "/ekyc/v1.2/request/create",
                accesstoken: "/v1/oauth/accessToken"
            },
            clientId: "",
            clientSecret: "",
            livenessConfig: {
                type: 'facetec',
                zoomLicenseKey: zoomLicenseKey,
                zoomServerBaseURL: zoomServerBaseURL,
                zoomPublicKey: zoomPublicKey,
                zoomAuthURL: zoomAuthURL
            },
            configHeader: "",
            stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
            titleColor: "#091E42CC", // 80%
            subTitleColor: "#091E4299", // 60%
            closeColor: "#091E42CC", // 80%
            buttonCaptureColor: "#1182E7",
            titleButtonCaptureColor: "#ffffff",
            backgroundColor: "#ffffff",
            requestId: '',
            accessToken: ''
        },
        editOCR: {
            fullName: '',
            dateOfBirth: '',
            gender: '',
            idNumber: '',
            givenDate: '',
            placeOfIssue: '',
            permanentAddress: ''
        }
    }

    static stopStream = () => {
        this.appdata.media?.getTracks().forEach(function (track) {
            if (track.readyState == 'live') {
                track.stop();
                // track.applyConstraints({
                //     advanced: [{ zoom: this.appdata.defaultZoom}]
                // }).then(() => {
                //     console.log('Zoom reset to default:');
                // }).catch(error => {
                //     console.error('Failed to reset zoom:', error);
                // });
            }

            //  // Đặt lại zoom nếu track video tồn tại
            // const videoTrack = tracks.find(track => track.kind === 'video');
            // if (videoTrack) {
              
            // }
        });
        setTimeout(() => {
            this.appdata.media = null;
        }, 1000);
    }

    static loadMediaStream = () => {
        console.log("loadMediaStream")
        if (this.appdata.media != null) {
            return Promise.resolve(this.appdata.media);
        } else {
            return new Promise((resolve, reject) => {
                if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.enumerateDevices) {
                    const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
                    const supports = navigator.mediaDevices.getSupportedConstraints();
                    console.log("supports", supports)
                    if (isMobile) {
                        navigator.mediaDevices.enumerateDevices()
                            .then(devices => {
                                const videoDevices = devices.filter(device => device.kind === 'videoinput');
                                console.log("videoDevices", videoDevices)
    
                                const backCameras = videoDevices.filter(device => /back|rear/i.test(device.label));
                                console.log("backCameras", backCameras)
                                var backCamera = backCameras.length > 0 ? backCameras[backCameras.length - 1] : null;
                                console.log("backCamera", backCamera)
    
                                if (!backCamera && videoDevices.length > 0) {
                                    backCamera = videoDevices[0];
                                    console.log("Fallback camera", backCamera)
                                }
    
                                if (backCamera) {
                                    var constraints = {
                                        video: {
                                            // ...(backCamera.deviceId ? { deviceId: { exact: backCamera.deviceId } } : {}),
                                            height: { ideal: 800 },
                                            width: { ideal: 600 },
                                            frameRate: { ideal: 30 },
                                            facingMode: "environment"
                                        }
                                    };
    
                                    return navigator.mediaDevices.getUserMedia(constraints);
                                } else {
                                    throw new Error('No back camera found');
                                }
                            })
                            .then(stream => {
                                this.appdata.media = stream;
                                const videoTrack = stream.getVideoTracks()[0];
                                const capabilities = videoTrack.getCapabilities();
                                // const settings = videoTrack.getSettings();
    
                                // Kiểm tra và áp dụng zoom nếu camera hỗ trợ
                                if (capabilities.zoom) {
                                    // let defaultZoom = settings.zoom || 1;
                                    // this.appdata.defaultZoom =  defaultZoom
                                    
                                    // let newZoom = defaultZoom * 1.5; // Tăng zoom thêm 1.5 lần
                                    videoTrack.applyConstraints({
                                        advanced: [{ zoom: 1.5 }]
                                    }).then(() => {
                                        console.log('Zoom applied successfully to:', newZoom);
                                    }).catch(error => {
                                        console.error('Failed to apply zoom:', error);
                                    });
                                }
                                
                                resolve(stream);
                            })
                            .catch(error => {
                                console.error("Error accessing the camera: ", error);
                                reject(error);
                            });
                    } else {
                        var videoObj = {
                            width: { ideal: 800 },
                            height: { ideal: 600 },
                            facingMode: "user",
                            frameRate: { ideal: 30 }
                        };
                        const constraints = { video: videoObj };
    
                        navigator.mediaDevices.getUserMedia(constraints)
                            .then(stream => {
                                this.appdata.media = stream;
                                resolve(stream);
                            })
                            .catch(error => {
                                console.error("Error accessing the camera: ", error);
                                reject(error);
                            });
                    }
                } else {
                    reject("Media devices not supported");
                }
            });
        }
    };
    

    static rootState = {
    }
    static async loadDataFromLocal() {
        var data = localStorage.getItem("userdata")
        if (data) {
            this.userdata = JSON.parse(data)
        }
        console.log("this.userdata", this.userdata)
    }
    static updateDataToLocal() {
        localStorage.setItem("userdata", JSON.stringify(this.userdata))
    }
}
