import StoreManager from "../appdata";
import Helpers from "../helpers";
import { screenIDState } from "../helpers";
import { Buffer } from 'buffer';
import TrueID from '../index'

export const getAccessToken = () =>{


    // let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader)
    // if (configHeader.Authorization) {
    //   StoreManager.appdata.accessToken = configHeader.Authorization.replace('Bearer ', '');
    //   callCreateRequest()      
    //   return
    // }

    if(StoreManager.appdata.configEkyc.accessToken != ''){
      StoreManager.appdata.accessToken = StoreManager.appdata.configEkyc.accessToken;
      callCreateRequest() 
      return
    }

    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    
    const raw = JSON.stringify({
      "client_id": StoreManager.appdata.configEkyc.clientId,
      "client_secret": StoreManager.appdata.configEkyc.clientSecret,
      "grant_type": "client_credentials"
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.accesstoken}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
            StoreManager.appdata.accessToken = result.access_token  
            callCreateRequest()      
      })
      .catch((error) => console.error(error));
}

export const callGetProdKey = () => {
  const myHeaders = new Headers();
  myHeaders.append("ClientID", `${StoreManager.appdata.configEkyc.clientId}`);
  myHeaders.append("user-agent-x", "web");

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow"
  };

  fetch(`${StoreManager.appdata.configEkyc.livenessConfig.zoomAuthURL}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      var b = new Buffer(result.data, "base64")
      var s = b.toString();
      
      StoreManager.appdata.facetecProdKey = s 
    })
    .catch((error) => console.error(error));
}

export const callCreateRequest = () =>{

  if(StoreManager.appdata.configEkyc.requestId != ''){
    StoreManager.appdata.requestId = StoreManager.appdata.configEkyc.requestId 
    return
  }



  
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
    myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
    if(StoreManager.appdata.configEkyc.configHeader != ""){
      let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
      Object.keys(configHeader).forEach(key => {
        myHeaders.append(key, configHeader[key]);
      });      
    }
    
    const raw = JSON.stringify({
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.createrequest}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        StoreManager.appdata.requestId = result.request_id  
      })
      .catch((error) => console.error(error));
}

export const callAPIFront = (id_card) =>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
    myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
    if(StoreManager.appdata.configEkyc.configHeader != ""){
      let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
      Object.keys(configHeader).forEach(key => {
        myHeaders.append(key, configHeader[key]);
      });      
    }
    StoreManager.appdata.rawImage.front = id_card
    const raw = JSON.stringify({
      "request_id": StoreManager.appdata.requestId,
      "device_model": "device_model",
      "platform": "web",
      "device_id": "device_id",
      "app_version": "app_version",
      "os_version": "os_version",
      "image_id": "image_id",
      "id_card": id_card
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    // fetch("/api/ekyc/v1.2/id/verify/front", requestOptions)
    fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.front}`, requestOptions)

    .then((response) => response.json())
    .then((result) => {
      
        if (result.result == 1) {
          StoreManager.appdata.thisFront.updateSuccess()
            // StoreManager.appdata.thisApp.nextStep()
        } else {
          let error = Helpers.errorByCode(result.result)
          StoreManager.appdata.thisFront.setState({status: screenIDState.CALL_API_FAILED, errorMessage: error.message})
        }
    })
      .catch((error) => console.error(error));
}

export const callAPIBack = (id_card) =>{
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
    myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
    if(StoreManager.appdata.configEkyc.configHeader != ""){
      let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
      Object.keys(configHeader).forEach(key => {
        myHeaders.append(key, configHeader[key]);
      });      
    }
    StoreManager.appdata.rawImage.back = id_card

    const raw = JSON.stringify({
      "request_id": StoreManager.appdata.requestId,
      "device_model": "device_model",
      "platform": "web",
      "device_id": "device_id",
      "app_version": "app_version",
      "os_version": "os_version",
      "image_id": "image_id",
      "id_card": id_card
    });
    
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };
    
    // fetch("/api/ekyc/v1.2/id/verify/back", requestOptions)
    fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.back}`, requestOptions)

    .then((response) => response.json())
    .then((result) => {
        console.log("result", result)
        if (result.result == 1) {
          StoreManager.appdata.thisBack.updateSuccess()
          
        } else {
          let error = Helpers.errorByCode(result.result)
          StoreManager.appdata.thisBack.setState({status: screenIDState.CALL_API_FAILED, errorMessage: error.message})
        }
    })
      .catch((error) => console.error(error));
}


export const callAPIOcr = () =>{
  const myHeaders = new Headers();
  myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
  myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
  myHeaders.append("Content-Type", "application/json");
  if(StoreManager.appdata.configEkyc.configHeader != ""){
    let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
    Object.keys(configHeader).forEach(key => {
      myHeaders.append(key, configHeader[key]);
    });      
  }
  
  const raw = JSON.stringify({
    "request_id": StoreManager.appdata.requestId,
  });
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  // fetch("/api/ekyc/v1.2/id/ocr", requestOptions)
  fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.ocr}`, requestOptions)

    .then((response) => response.json())
    .then((result) => {
      console.log("/api/ekyc/v1.2/id/ocr",result)
      if (result.result == 1) {
        StoreManager.appdata.thisOcr.setState({
          fullName: result.data_fields?.name?.value,
              dateOfBirth:result.data_fields?.dob?.value,
              gender: result.data_fields?.gender?.value,
              idNumber: result.data_fields?.id_number?.value,
              givenDate: result.data_fields?.given_date?.value,
              placeOfIssue: result.data_fields?.given_place?.value,
              permanentAddress: result.data_fields?.id_address?.value
        })
      }
     
    })
    .catch((error) => console.error(error));
}


export const callAPISelfie = (selfie) =>{
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
  myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
  if(StoreManager.appdata.configEkyc.configHeader != ""){
    let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
    Object.keys(configHeader).forEach(key => {
      myHeaders.append(key, configHeader[key]);
    });      
  }

  StoreManager.appdata.rawImage.selfie = selfie

  const raw = JSON.stringify({
    "request_id": StoreManager.appdata.requestId,
    "device_model": "device_model",
    "platform": "web",
    "device_id": "device_id",
    "app_version": "app_version",
    "os_version": "os_version",
    "image_id": "image_id",
    "selfie": selfie
  });
  
  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };
  
  // fetch("/api/ekyc/v1.2/selfie/verify", requestOptions)
  fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.selfie}`, requestOptions)

    .then((response) => response.json())
    .then((result) => {
      console.log(result)
      if (result.result == 1) {
        StoreManager.appdata.thisApp.nextStep()
      } else {
        let error = Helpers.errorByCode(result.result)
        StoreManager.appdata.thisSelfie.setState({status: screenIDState.CALL_API_FAILED, errorMessage: error.message})
      }
    })
    .catch((error) => console.error(error));
}


export const callAPIComplete = () =>{
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
  myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
  if(StoreManager.appdata.configEkyc.configHeader != ""){
    let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
    Object.keys(configHeader).forEach(key => {
      myHeaders.append(key, configHeader[key]);
    });      
  }

  const raw = JSON.stringify({
    "request_id": StoreManager.appdata.requestId,
    "pull_ekyc_result": 1
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  // fetch("/api/ekyc/v1.2/complete", requestOptions)
  fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.complete}`, requestOptions)

    .then((response) => response.json())
    .then((result) => {
      Helpers.loading.hideLoadingSpinner()

      console.log("/api/ekyc/v1.2/complete", result)
      let sdkResult = {
        code: 1,
        errorMessage: "",
        clientId: result.client_id,
        requestId: result.request_id,
        decision: result.kyc_result.decision,
        faceMatching:result.kyc_result.compare,
        idInfo: result.kyc_result.compare,
        rawImage:StoreManager.appdata.rawImage,
        ekycResult: result
      }
      StoreManager.appdata.callback(sdkResult)
      TrueID.close()

    })
    .catch((error) => console.error(error));
}

export const callAPICompleteWithOCR = () =>{
  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("ClientID", StoreManager.appdata.configEkyc.clientId);
  myHeaders.append("Authorization", `Bearer ${StoreManager.appdata.accessToken}`);
  if(StoreManager.appdata.configEkyc.configHeader != ""){
    let configHeader = JSON.parse(StoreManager.appdata.configEkyc.configHeader);
    Object.keys(configHeader).forEach(key => {
      myHeaders.append(key, configHeader[key]);
    });      
  }

  const raw = JSON.stringify({
    "request_id": StoreManager.appdata.requestId,
    "pull_ekyc_result": 1,
    "id_number": StoreManager.appdata.editOCR.idNumber,
    "full_name": StoreManager.appdata.editOCR.fullName,
    "dob": StoreManager.appdata.editOCR.dateOfBirth,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow"
  };

  // fetch("/api/ekyc/v1.2/complete", requestOptions)
  fetch(`${StoreManager.appdata.configEkyc.domain}${StoreManager.appdata.configEkyc.configEndpoint.complete}`, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      // Helpers.loading.hideLoadingSpinner()
      console.log("/api/ekyc/v1.2/complete", result)
      let sdkResult = {
        code: 1,
        errorMessage: "",
        clientId: result.client_id,
        requestId: result.request_id,
        decision: result.kyc_result.decision,
        faceMatching:result.kyc_result.compare,
        idInfo: result.kyc_result.compare,
        rawImage:StoreManager.appdata.rawImage,
        ekycResult: result
      }
      StoreManager.appdata.callback(sdkResult)
      TrueID.close()

    })
    .catch((error) => console.error(error));
}