import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import StoreManager from './appdata';


var root = null;
var container = null; // Khai báo biến container ở cấp độ cao hơn để dễ dàng truy cập

function start(configEkyc, callback) {

    StoreManager.appdata.configEkyc = configEkyc
    StoreManager.appdata.callback = callback

    container = document.createElement('div');
    container.style.position = 'fixed';
    container.style.zIndex = 16777100;
    container.style.width = '100%';
    container.style.height = '100%';
    container.style.top = '0';
    container.style.left = '0';
    container.style.right = '0';
    container.style.bottom = '0';    

 
    document.body.appendChild(container);

    root = ReactDOM.createRoot(container);
    root.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>
    );
}

function close() {
  const tracks = StoreManager.appdata.media?.getTracks();

    tracks?.forEach((track) => {
      console.log("track", track)

    //   track.applyConstraints({
    //     advanced: [{ zoom: 1.0}]
    // }).then(() => {
    //     console.log('Zoom reset to default:');
    // }).catch(error => {
    //     console.error('Failed to reset zoom:', error);
    // });
      track.stop();
      
    });
    console.log("tracks", tracks)
    StoreManager.appdata.media = null
    setTimeout(()=>{
      if (root) {
          root.unmount();
          container.parentNode.removeChild(container); // Sau đó xóa container div
          root = null;
          container = null;
      }
    }, 500)
   
}

export default { start, close };  // Xuất các hàm này như một phần của SDK


if (process.env.REACT_APP_ENV === 'development') {
  let zoomServerBaseURL = "https://api-gateway-uat.trueid.vn/facetec"
  let zoomAuthURL = "https://api-gateway-uat.trueid.vn/facetec/liveness/key"
  let zoomLicenseKey = "dSERDnSNV8KzqajJJMEfA353JgNV27jb"
  
  let zoomPublicKey =
      "-----BEGIN PUBLIC KEY-----\n" +
          "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtYdqkDpAE9umyJDfapTa\n" +
          "z+nIs3f8Qzc15v268pXGO1lNdRa5Qi1QtxNkfh9D043/8ySNbLWt6zpFEFi98geU\n" +
          "kMLrqEA9UeeRHBvxjfBl+8DMsP1QtOsgbukyNHZIDRNDcn0nnSvxdjzvTsnYrR6N\n" +
          "YHJ2jyrObKR9bGBPInjfNGjz0L2jFcWYNmO//5wA2Wza4uqOZS28sjMHmTcmiWfX\n" +
          "tW9oUQTTKcFA9uZu2A+3hvwjWsAxg4cP1B7BLRZQDhvKfgs2IQKfcbwTDqKZunK/\n" +
          "ooH/fMuPZLFnevxlyFAsC+RJ1Tb355gaAmkFDiVKZJzOzY8gWjVpA6fxXrJe1V9o\n" +
          "/wIDAQAB\n" +
          "-----END PUBLIC KEY-----";

          let  configEkyc = {
            domain:'/trueidapi',
            configEndpoint:{
                front : "/ekyc/v1.2/id/verify/front",
                back : "/ekyc/v1.2/id/verify/back",
                selfie : "/ekyc/v1.2/selfie/verify",
                complete : "/ekyc/v1.2/complete",
                nfcqrverify : "/ekyc/v2.0/nfcqr/verify",
                nfcrar : "/ekyc/v1.2/nfcqr/upload",
                ocr : "/ekyc/v1.2/id/ocr",
                nfcbshield : "/ekyc/v1.2/nfcqr/upload",
                createrequest : "/ekyc/v1.2/request/create",
                accesstoken : "/auth/v1/oauth/accessToken"
            },
            clientId:"3c4bda2dfbafd5a82c16eada97c8499e",
            clientSecret: "Yms0u7uYPmIJ8/S6Q5cjYmED7FEo/u851WfPP0M4dpI=",
            livenessConfig: {
                type:'facetec',
                zoomLicenseKey:zoomLicenseKey,
                zoomServerBaseURL:zoomServerBaseURL,
                zoomPublicKey:zoomPublicKey,
                zoomAuthURL:zoomAuthURL
            },
            configHeader:"{\"header1\":\"header1_value\", \"header2\":\"header2_value\"}",
            stepVerification: ["FRONT", "BACK", "SELFIE", "EDITOCR"],
            titleColor: "#091E42CC",//80%
            subTitleColor: "#091E4299",//60%
            closeColor: "#091E42CC",//80%
            buttonCaptureColor:"#1182E7",
            titleButtonCaptureColor:"#ffffff",
            backgroundColor:"#ffffff",
            requestId:"",
            accessToken:""
        }
        let callBack = (result) => {
          console.log("SDK result", result)
        }
  console.log("start")
  start(configEkyc, callBack)
}

// setTimeout(()=>{
//   close()
// }, 5000)