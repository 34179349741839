import StoreManager from "../appdata";
import languageSDK from "../languages";
import { Config } from "./Config";
// import { FaceTecSDK } from "../../core-sdk/FaceTecSDK.js/FaceTecSDK";
import { LivenessCheckProcessor } from "./processors/LivenessCheckProcessor";
import { SampleAppUtilities } from "./utilities/SampleAppUtilities";



// import AppDataManager from "../appdatas";


export var ZoomApp =  (function () {
  var latestEnrollmentIdentifier = "";
  var latestProcessor;
  var latestSessionResult = null;
  var latestIDScanResult = null;
  let FaceTecSDK = window.FaceTecSDK 
  // Wait for onload to be complete before attempting to access the Browser SDK.
  window.onload = function () {
    if (window.FaceTecSDK) {
        // Sử dụng FaceTecSDK ở đây
        FaceTecSDK = window.FaceTecSDK
      }
      console.log("window.onload")
      SampleAppUtilities.formatUIForDevice();
      // Set a the directory path for other FaceTec Browser SDK Resources.
      FaceTecSDK.setResourceDirectory(`/core-sdk/FaceTecSDK.js/resources`);
      // Set the directory path for required FaceTec Browser SDK images.
      FaceTecSDK.setImagesDirectory(`/core-sdk/FaceTec_images`);

  };

  function reloadSDK(productionKey) {
  
    FaceTecSDK.setResourceDirectory(`/core-sdk/FaceTecSDK.js/resources`);

    // Set the directory path for required FaceTec Browser SDK images.
    FaceTecSDK.setImagesDirectory(`/core-sdk/FaceTec_images`);
    
    FaceTecSDK.setCustomization(Config.retrieveConfigurationWizardCustomization(FaceTecSDK));
    // ThemeHelpers.setAppTheme();
    // Initialize FaceTec Browser SDK and configure the UI features.
    Config.initializeFromAutogeneratedConfig(FaceTecSDK, productionKey, function (initializedSuccessfully) {


       
        if (languageSDK.language.language == 'vi') {
          var vi = {
            "FaceTec_action_ok": "Tiếp tục",
            "FaceTec_action_im_ready": "Bắt đầu",
            "FaceTec_action_try_again": "Thử lại",
            "FaceTec_action_continue": "Tiếp tục",
            "FaceTec_action_take_photo": "TAKE PHOTO",
            "FaceTec_action_accept_photo": "ACCEPT",
            "FaceTec_action_retake_photo": "RETAKE",
            "FaceTec_action_confirm": "Xác Nhận Thông Tin",
            
            "FaceTec_accessibility_cancel_button": "Cancel",
            "FaceTec_accessibility_tap_guidance": "Nhấn đúp vào màn hình để hướng dẫn căn chỉnh khuôn mặt",
            "FaceTec_accessibility_key_down_guidance": "Nhấn Enter hoặc Spacebar để hướng dẫn căn chỉnh khuôn mặt",
            "FaceTec_accessibility_torch_button": "Toggle Torch",
        
            "FaceTec_accessibility_feedback_move_phone_away": "Điện thoại quá gần",
            "FaceTec_accessibility_feedback_move_phone_closer": "Điện thoại quá xa",
            "FaceTec_accessibility_feedback_face_too_far_left": "Khuôn mặt quá lệch sang trái",
            "FaceTec_accessibility_feedback_face_too_far_right": "Khuôn mặt quá lệch sang phải",
            "FaceTec_accessibility_feedback_face_too_low": "Khuôn mặt quá thấp",
            "FaceTec_accessibility_feedback_face_too_high": "Khuôn mặt quá cao",
            "FaceTec_accessibility_feedback_face_rotated_too_far_left": "Khuôn mặt xoay quá nhiều sang trái",
            "FaceTec_accessibility_feedback_face_rotated_too_far_right": "Khuôn mặt xoay quá nhiều sang phải",
            "FaceTec_accessibility_feedback_face_looking_too_far_left": "Khuôn mặt nhìn quá sang trái",
            "FaceTec_accessibility_feedback_face_looking_too_far_right": "Khuôn mặt nhìn quá sang phải",
            "FaceTec_accessibility_feedback_face_not_in_camera": "Khuôn mặt không trong khung hình hoặc quá xa",
            "FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Giữ thiết bị ở tầm mắt",
            "FaceTec_accessibility_feedback_move_away_web": "Điện thoại quá gần",
            "FaceTec_accessibility_feedback_move_closer_web": "Điện thoại quá xa",
            "FaceTec_accessibility_feedback_hold_to_eye_level_web": "Di chuyển camera lên tầm mắt",
        
            "FaceTec_presession_frame_your_face": "Đưa khuôn mặt vào khung hình",
            "FaceTec_presession_look_straight_ahead": "Nhìn thẳng về phía trước",
            "FaceTec_presession_hold_steady3": "Giữ ổn định: 3",
            "FaceTec_presession_hold_steady2": "Giữ ổn định: 2",
            "FaceTec_presession_hold_steady1": "Giữ ổn định: 1",
            "FaceTec_presession_eyes_straight_ahead": "Nhìn thẳng về phía trước",
            "FaceTec_presession_remove_dark_glasses": "Vui lòng bỏ kính mát",
            "FaceTec_presession_neutral_expression": "Giữ mặt bình thường, không cười",
            "FaceTec_presession_conditions_too_bright": "Điều kiện quá sáng",
            "FaceTec_presession_brighten_your_environment": "Làm sáng môi trường xung quanh",
            "FaceTec_presession_position_face_straight_in_oval": "Nhìn thẳng về phía trước",
        
            "FaceTec_feedback_center_face": "Giữ khuôn mặt nhìn thẳng",
            "FaceTec_feedback_face_not_found": "Đưa khuôn mặt vào khung hình",
            "FaceTec_feedback_move_phone_away": "Di chuyển ra xa",
            "FaceTec_feedback_move_away_web": "Di chuyển ra xa",
            "FaceTec_feedback_move_phone_closer": "Di chuyển lại gần",
            "FaceTec_feedback_move_web_closer": "Di chuyển khuôn mặt lại gần và lấp đầy hình bầu dục",
            "FaceTec_feedback_move_web_even_closer": "Gần hơn nữa",
            "FaceTec_feedback_move_phone_to_eye_level": "Đưa camera ngang tầm mắt",
            "FaceTec_feedback_move_to_eye_level_web": "Nhìn thẳng vào camera",
            "FaceTec_feedback_face_not_looking_straight_ahead": "Nhìn thẳng về phía trước",
            "FaceTec_feedback_face_not_upright": "Giữ tư thế đầu thẳng",
            "FaceTec_feedback_face_not_upright_mobile": "Giữ đầu thẳng",
            "FaceTec_feedback_hold_steady": "Giữ cố định",
            "FaceTec_feedback_use_even_lighting": "Canh ánh sáng đều khuôn mặt",
        
            "FaceTec_instructions_header_ready_desktop": "Xác thực khuôn mặt",
            "FaceTec_instructions_header_ready_1_mobile": "Xác thực",
            "FaceTec_instructions_header_ready_2_mobile": "khuôn mặt",
            "FaceTec_instructions_message_ready_desktop": "Đưa khuôn mặt vào trong khung hình. Máy sẽ tự động chụp hình chân dung",
            "FaceTec_instructions_message_ready_1_mobile": "Đưa gương mặt vào trong khung hình",
            "FaceTec_instructions_message_ready_2_mobile": "Đưa ra xa, sau đó đưa lại gần",
            "FaceTec_instructions_header_lighting": "Canh chỉnh ánh sáng đều khuôn mặt",
            "FaceTec_instructions_header_face_angle": "Đưa camera ngang tầm mắt",
        
            "FaceTec_retry_header": "Ảnh không hợp lệ",
            "FaceTec_retry_subheader_message": "Cần một hình selfie rõ nét hơn.",
            "FaceTec_retry_your_image_label": "Ảnh của bạn",
            "FaceTec_retry_ideal_image_label": "Ảnh tham khảo",
            "FaceTec_retry_instruction_message_1": "Giữ mặt bình thường, không cười",
            "FaceTec_retry_instruction_message_2": "Không bị bóng mờ hoặc chói sáng",
            "FaceTec_retry_instruction_message_3": "Quá mờ, vui lòng lau sạch máy ảnh",
        
            "FaceTec_camera_feed_issue_header": "Vấn đề với nguồn cấp dữ liệu camera",
            "FaceTec_camera_feed_issue_header_mobile": "Vấn đề với nguồn cấp dữ liệu camera",
            "FaceTec_camera_feed_issue_subheader_message_mobile": "Ứng dụng này chặn các cấu hình webcam đáng ngờ. <br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Tìm hiểu thêm tại đây</a>.",
            "FaceTec_camera_feed_issue_subheader_message": "Hệ thống này không thể được xác minh do các vấn đề sau:",
            "FaceTec_camera_feed_issue_table_header_1": "Vấn đề có thể",
            "FaceTec_camera_feed_issue_table_header_2": "Giải pháp",
            "FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Quyền truy cập camera không được ghi nhớ trong Firefox.",
            "FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Kiểm tra nhớ quyền.",
            "FaceTec_camera_feed_issue_table_row_1_cell_1": "Camera đã được sử dụng bởi ứng dụng khác.",
            "FaceTec_camera_feed_issue_table_row_1_cell_2": "Đóng ứng dụng khác.",
            "FaceTec_camera_feed_issue_table_row_2_cell_1": "Ứng dụng bên thứ ba đang chỉnh sửa video.",
            "FaceTec_camera_feed_issue_table_row_2_cell_2": "Đóng/gỡ cài đặt ứng dụng khác.",
            "FaceTec_camera_feed_issue_table_row_3_cell_1": "Phần cứng không đủ điều kiện an toàn.",
            "FaceTec_camera_feed_issue_table_row_3_cell_2": "Sử dụng thiết bị khác.",
            "FaceTec_camera_feed_issue_subtable_message": "Ứng dụng này chặn các cấu hình webcam đáng ngờ. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Tìm hiểu thêm tại đây</a>.",
            "FaceTec_camera_feed_issue_action": "THỬ LẠI DÙ SAO",
            "FaceTec_camera_feed_issue_action_firefox_permissions_error": "Đồng Ý",
        
            "FaceTec_camera_permission_header": "Truy cập camera",
            "FaceTec_camera_permission_message": "Quyền truy cập camera bị vô hiệu hóa. <br/>Vui lòng kiểm tra cài đặt hệ điều hành và trình duyệt của bạn.",
            "FaceTec_camera_permission_message_enroll": "Cho phép truy cập camera để chụp ảnh chân dung",
            "FaceTec_camera_permission_message_auth": "Quyền truy cập camera đã bị từ chối. Nhấn MỞ THIẾT ĐẶT để cho phép truy cập camera",
            "FaceTec_camera_permission_enable_camera": "Cho phép",
            "FaceTec_camera_permission_launch_settings": "MỞ THIẾT ĐẶT",
        
            "FaceTec_enter_fullscreen_header": "",
            "FaceTec_enter_fullscreen_message": "Trước khi bắt đầu, vui lòng nhấn vào nút bên dưới để mở chế độ toàn màn hình.",
            "FaceTec_enter_fullscreen_action": "MỞ CHẾ ĐỘ TOÀN MÀN HÌNH",
        
            "FaceTec_initializing_camera": "Đang bảo mật nguồn cấp dữ liệu camera",
        
            "FaceTec_idscan_type_selection_header": "Chuẩn bị quét<br/>tài liệu ID của bạn",
            "FaceTec_idscan_capture_id_front_instruction_message": "Hiện mặt trước của ID",
            "FaceTec_idscan_capture_id_back_instruction_message": "Hiện mặt sau của ID",
            "FaceTec_idscan_review_id_front_instruction_message": "Xác nhận ảnh rõ ràng và dễ đọc",
            "FaceTec_idscan_review_id_back_instruction_message": "Xác nhận ảnh rõ ràng và dễ đọc",
            "FaceTec_idscan_additional_review_message": "Yêu cầu đánh giá bổ sung<br/>",
            "FaceTec_idscan_ocr_confirmation_main_header": "Xem Lại & Xác Nhận",
            "FaceTec_idscan_ocr_confirmation_scroll_message": "Cuộn xuống",
        
            "FaceTec_result_success_message": "Xác nhận hình ảnh thành công",
            "FaceTec_result_facescan_upload_message": "Đang xử lý\nMã hoá\n3D Chân Dung",
            "FaceTec_result_idscan_upload_message": "Đang tải lên<br/>Bản mã hóa<br/>Tài liệu ID",
            "FaceTec_result_idscan_unsuccess_message": "Ảnh ID<br/>Không Khớp<br/>Khuôn Mặt Người Dùng",
            "FaceTec_result_idscan_success_front_side_message": "Quét ID Hoàn Tất",
            "FaceTec_result_idscan_success_front_side_back_next_message": "Mặt Trước của ID<br/>Đã Được Quét",
            "FaceTec_result_idscan_success_back_side_message": "Quét ID Hoàn Tất",
            "FaceTec_result_idscan_success_passport_message": "Quét Hộ Chiếu Hoàn Tất",
            "FaceTec_result_idscan_success_user_confirmation_message": "Quét Ảnh ID<br/>Hoàn Tất",
            "FaceTec_result_idscan_success_additional_review_message": "Chụp Ảnh ID<br/>Hoàn Tất",
            "FaceTec_result_idscan_retry_face_did_not_match_message": "Khuôn Mặt Không Đủ<br/>Khớp",
            "FaceTec_result_idscan_retry_id_not_fully_visible_message": "Tài liệu ID<br/>Không Hoàn Toàn Rõ Ràng",
            "FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "Văn Bản ID Không Rõ",
            "FaceTec_result_idscan_retry_id_type_not_suppported_message": "Loại ID Không Được Hỗ Trợ<br/>Vui lòng Sử Dụng ID Khác",
            "FaceTec_result_idscan_retry_barcode_not_read_message": "Mã Vạch Không Thể Quét<br/>Vui lòng Thử Lại",
            
            "FaceTec_result_facemap_upload_message": "Đang xử lý",
            "FaceTec_result_facemap_success_message": "Xác nhận hình ảnh thành công",
        
            "FaceTec_locked_header": "Bạn đã thử quá nhiều lần. Vui lòng quay lại sau.",
            "FaceTec_locked_header_ready": "Zoom is Ready",
            "FaceTec_locked_try_again_in": "Bạn đã thử quá nhiều lần. Vui lòng quay lại sau."
        }
                
          FaceTecSDK.configureLocalization(vi);
        }

     console.log("initializedSuccessfully", initializedSuccessfully)
      if (initializedSuccessfully) {
       
        SampleAppUtilities.enableControlButtons();
        SampleAppUtilities.setVocalGuidanceSoundFiles();
      } else {

      }
      SampleAppUtilities.displayStatus(FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus()));

      
    });
   SampleAppUtilities.formatUIForDevice();

    FaceTecSDK.setCustomization(Config.retrieveConfigurationWizardCustomization(FaceTecSDK));

    // SampleAppUtilities.formatUIForDevice();
  }
  // Initiate a 3D Liveness Check.
  function onLivenessCheckPressed() {
      SampleAppUtilities.fadeOutMainUIAndPrepareForSession();
      // Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
      getSessionToken(function (sessionToken) {
          latestProcessor = new LivenessCheckProcessor(sessionToken, ZoomApp);
      });
  }
  // Show the final result with the Session Review Screen.
  var onComplete;
  onComplete = function (sessionResult, idScanResult, latestNetworkResponseStatus) {

      latestSessionResult = sessionResult;
      latestIDScanResult = idScanResult;
      if (latestProcessor.isSuccess()) {
          // Show successful message to screen
          SampleAppUtilities.displayStatus("Success");
      
      }
      else {
          // Show early exit message to screen.  If this occurs, please check logs.
          SampleAppUtilities.displayStatus("Session exited early, see logs for more details. Latest getStatus(): " + FaceTecSDK.getStatus());
          // Check for server offline
          if (isNetworkResponseServerIsOffline(latestNetworkResponseStatus) === true) {
              showAdditionalScreensServerIsDown();
              return;
          }
      }
      SampleAppUtilities.showMainUI();
  };
  // Check for server down status
  function isNetworkResponseServerIsOffline(networkResponseStatus) {
      return (networkResponseStatus >= 500);
  }
  // Set a new customization for FaceTec Browser SDK.
  function onDesignShowcasePressed() {
      // ThemeHelpers.showNewTheme();
  }
  function onVocalGuidanceSettingsButtonPressed() {
      SampleAppUtilities.setVocalGuidanceMode();
  }
  // Display audit trail images captured from user's last FaceTec Browser SDK Session (if available).
  function onViewAuditTrailPressed() {
      SampleAppUtilities.showAuditTrailImages(latestSessionResult, latestIDScanResult);
  }
  // Get the Session Token from the server
  function getSessionToken(sessionTokenCallback) {
      // Only handle session token error once
      var sessionTokenErrorHasBeenHandled = false;
      var XHR = new XMLHttpRequest();
      XHR.open("GET", StoreManager.appdata.configEkyc.livenessConfig.zoomServerBaseURL + "/session-token");
      XHR.setRequestHeader("X-Device-Key", StoreManager.appdata.configEkyc.livenessConfig.zoomLicenseKey);
      XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));
      XHR.onreadystatechange = function () {
          if (this.readyState === XMLHttpRequest.DONE) {
              var sessionToken = "";
              try {
                  // Attempt to get the sessionToken from the response object.
                  sessionToken = JSON.parse(this.responseText).sessionToken;
                  // Something went wrong in parsing the response. Return an error.
                  if (typeof sessionToken !== "string") {
                      if (sessionTokenErrorHasBeenHandled === false) {
                          sessionTokenErrorHasBeenHandled = true;
                          if (isNetworkResponseServerIsOffline(XHR.status)) {
                              showAdditionalScreensServerIsDown();
                          }
                          else {
                              onServerSessionTokenError();
                          }
                      }
                      return;
                  }
              }
              catch (_a) {
                  // Something went wrong in parsing the response. Return an error.
                  if (sessionTokenErrorHasBeenHandled === false) {
                      sessionTokenErrorHasBeenHandled = true;
                      if (isNetworkResponseServerIsOffline(XHR.status)) {
                          showAdditionalScreensServerIsDown();
                      }
                      else {
                          onServerSessionTokenError();
                      }
                  }
                  return;
              }
              SampleAppUtilities.hideLoadingSessionToken();
              sessionTokenCallback(sessionToken);
          }
      };
      // Wait 3s, if the request is not completed yet, show the session token loading screen
      window.setTimeout(function () {
          if (XHR.readyState !== XMLHttpRequest.DONE) {
              SampleAppUtilities.showLoadingSessionToken();
          }
      }, 3000);
      XHR.onerror = function () {
          // Something went wrong in the XHR request
          if (sessionTokenErrorHasBeenHandled === false) {
              sessionTokenErrorHasBeenHandled = true;
              if (isNetworkResponseServerIsOffline(XHR.status)) {
                  showAdditionalScreensServerIsDown();
              }
              else {
                  onServerSessionTokenError();
              }
          }
      };
      XHR.send();
  }
  function showAdditionalScreensServerIsDown() {
    //   AdditionalScreens.showServerUpGradeView();
  }
  function onServerSessionTokenError() {
      SampleAppUtilities.handleErrorGettingServerSessionToken();
  }
  var getLatestEnrollmentIdentifier;
  getLatestEnrollmentIdentifier = function () {
      return latestEnrollmentIdentifier;
  };
  var clearLatestEnrollmentIdentifier;
  clearLatestEnrollmentIdentifier = function () {
      latestEnrollmentIdentifier = "";
  };
  function exitAdditionalScreen() {
    //   AdditionalScreens.exitAdditionalScreen(SampleAppUtilities.showMainUI);
  }
  return {
      onLivenessCheckPressed: onLivenessCheckPressed,
      // onEnrollUserPressed: onEnrollUserPressed,
      // onAuthenticateUserPressed: onAuthenticateUserPressed,
      // onPhotoIDMatchPressed: onPhotoIDMatchPressed,
      onDesignShowcasePressed: onDesignShowcasePressed,
      onComplete: onComplete,
      getLatestEnrollmentIdentifier: getLatestEnrollmentIdentifier,
      clearLatestEnrollmentIdentifier: clearLatestEnrollmentIdentifier,
      onVocalGuidanceSettingsButtonPressed: onVocalGuidanceSettingsButtonPressed,
      onViewAuditTrailPressed: onViewAuditTrailPressed,
      latestSessionResult: latestSessionResult,
      latestIDScanResult: latestIDScanResult,
      reloadSDK
  };
})();
